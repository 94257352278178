import TemplateModel from "../../../Loyalty/Models/Template/TemplateModel";
import PrivateLeagueModel from "../PrivateLeagueModel";

export default class PrivateLeagueRankingsModel {
  public templateId: string = "";
  public templateModel: TemplateModel = null;
  public privateLeagueId: string = "";
  public privateLeagueModel: PrivateLeagueModel = null;
  public position: string = "";
  public points: number = 0;
  public type: string = "private_league";
}
