import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../../IdMapping/IdMappingService";
import ClassicQuizFilters from "../Models/ClassicQuiz/Filters/ClassicQuizFilters";
import MiniGamesValidator from "../Validator/MiniGamesValidator";

export default class MiniGamesService {
  private idSchema: string = null;
  readonly idMapping: IdMappingService = null;
  readonly validator: MiniGamesValidator = null;
  readonly entityTypes = ["competition", "team", "player"];

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.idMapping = new IdMappingService(config);
    this.validator = new MiniGamesValidator(config);
  }

  public finalizeClassicQuizFilters = async (filters: ClassicQuizFilters) => {
    this.validator.validateFilters(filters);

    if (this.idSchema !== IdSchemaEnum.NATIVE) {
      this.validator.validateFilters(filters);
      const idsObj = await this.idMapping.getEntityIdsBySchemaId(
        Array.from(filters.entityIds),
        filters.entityType,
        IdSchemaEnum.NATIVE
      );
      filters.entityIds = idsObj[filters.entityType];
    }
  };
}
