import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import Loyalty from "../../Loyalty/Loyalty";
import TemplateModel from "../../Loyalty/Models/Template/TemplateModel";
import AcceptChallengeModel from "../../Challenges/Models/AcceptChallengeModel";
import Profile from "../../Profile/Profile";
import AcceptPrivateLeagueModel from "../Models/AcceptPrivateLeagueModel";
import PrivateLeagueModel from "../Models/PrivateLeagueModel";
import PrivateLeagueRankingsModel from "../Models/Rankings/PrivateLeagueRankingsModel";
import { LeagueType } from "../Types/types";
import ChallengeRankingsModel from "../../Challenges/Models/Rankings/ChallengeRankingsModel";
import Predictor from "../../Predictor/Predictor";
import PrivateLeaguePredictionsModel from "../Models/PrivateLeaguePredictionsModel";
import PrivateLeaguePredictionModel from "../Models/PrivateLeaguePredictionModel";
import ProfileModel from "../../Profile/Models/ProfileModel";
import PredictionResponseModel from "../../Predictor/Models/Predictions/PredictionResponseModel";

export default class PrivateLeagueService {
  private loyalty: Loyalty = null;
  private profile: Profile = null;
  private predictor: Predictor = null;

  constructor(config: SDKConfigurationModel) {
    this.loyalty = new Loyalty(config);
    this.profile = new Profile(config);
    this.predictor = new Predictor(config);
  }

  public setTemplateModels = async (privateLeague: PrivateLeagueModel) => {
    const templates = await this.loyalty.getTemplates();
    privateLeague.templateModel = templates.data.find(
      (template: TemplateModel) => template.id === privateLeague.templateId
    );

    if (privateLeague.pastTemplates && privateLeague.pastTemplates.length > 0) {
      privateLeague.pastTemplates.forEach((t) => {
        t.model = templates.data.find(
          (template: TemplateModel) => template.id === t.id
        );
      });
    }
  };

  public setTemplateModelsInLeagues = async (
    privateLeagues: PrivateLeagueModel[]
  ) => {
    const templates = await this.loyalty.getTemplates();
    privateLeagues.forEach((league: PrivateLeagueModel) => {
      league.templateModel = templates.data.find(
        (template: TemplateModel) => template.id === league.templateId
      );

      if (league.pastTemplates && league.pastTemplates.length > 0) {
        league.pastTemplates.forEach((t) => {
          t.model = templates.data.find(
            (template: TemplateModel) => template.id === t.id
          );
        });
      }
    });
  };

  public setTemplateModelsFromLeagueModels = (
    rankings: any[],
    type: LeagueType
  ) => {
    /**
     * There can be a case where the league will be with updated template id.
     * In that scenario we need to be sure that we will set the right template model.
     */

    if (type === "PRIVATE_LEAGUE") {
      rankings.forEach((ranking: PrivateLeagueRankingsModel) => {
        ranking.templateModel =
          ranking.templateId === ranking.privateLeagueModel.templateId
            ? ranking.privateLeagueModel.templateModel
            : ranking.privateLeagueModel.pastTemplates.find(
                (value) => value.id === ranking.templateId
              ).model;
      });

      return;
    }

    rankings.forEach((ranking: ChallengeRankingsModel) => {
      ranking.templateModel =
        ranking.templateId === ranking.challengeModel.templateId
          ? ranking.challengeModel.templateModel
          : ranking.challengeModel.pastTemplates.find(
              (value) => value.id === ranking.templateId
            ).model;
    });
  };

  public setProfileModel = async (
    acceptResponse: AcceptPrivateLeagueModel | AcceptChallengeModel
  ) => {
    const profileById = await this.profile
      .getById(acceptResponse.profileId)
      .getInfo();
    acceptResponse.profileModel = profileById;
  };

  public setModelsForPredictions = async (
    predictions: PrivateLeaguePredictionsModel
  ) => {
    if (predictions.data.length) {
      const profileIds = predictions.data.map(
        (value: PrivateLeaguePredictionModel) => value.profileId
      );
      const predictionIds = predictions.data.map(
        (value: PrivateLeaguePredictionModel) => value.predictionId
      );
      const profiles = await this.getProfileModels(profileIds);
      const predictionModels = await this.getPredictionModels(predictionIds);

      predictions.data.forEach((value: PrivateLeaguePredictionModel) => {
        value.profileModel = profiles.find(
          (profile: ProfileModel) => profile.id === value.profileId
        );
        value.predictionModel = predictionModels.find(
          (prediction: PredictionResponseModel) =>
            prediction.id === value.predictionId
        );
      });
    }

    return predictions;
  };

  private getProfileModels = async (
    profileIds: string[]
  ): Promise<ProfileModel[]> => {
    return await this.profile.getByIds(profileIds);
  };

  private getPredictionModels = async (predictionIds: string[]) => {
    return await this.predictor.getPredictionsByIds(predictionIds);
  };
}
