import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import MiniGamesHttps from "../../../Https/MiniGamesHttps";
import ClassicQuizParticipationBodyModel from "../Models/ClassicQuiz/ClassicQuizParticipationBodyModel";
import ClassicQuizFilters from "../Models/ClassicQuiz/Filters/ClassicQuizFilters";
import MiniGamesService from "../Service/MiniGamesService";

export default class MiniGamesFacade {
    readonly https: MiniGamesHttps = null;
    readonly service: MiniGamesService = null;

    constructor(config: SDKConfigurationModel) {
        this.https = new MiniGamesHttps(config);
        this.service = new MiniGamesService(config);
    }

    public getClassicQuizzes = async (filters?: ClassicQuizFilters, disableCache?: boolean) => {
        if (filters) {
            filters = new ClassicQuizFilters(filters);
            await this.service.finalizeClassicQuizFilters(filters);
        }

        return await this.https.getClassicQuizzes(filters, disableCache);
    };

    public getClassicQuizById = async (classicQuizId: string, disableCache?: boolean) => {
        return await this.https.getClassicQuizById(classicQuizId, disableCache);
    };

    public participateInClassicQuiz = async (classicQuizId: string, questions: ClassicQuizParticipationBodyModel[], includeCorrectOptionIds?: boolean) => {
        return await this.https.participateInClassicQuiz(classicQuizId, questions, includeCorrectOptionIds);
    };

    public getMyClassicQuizParticipations = async (classicQuizIds: string[]) => {
        return await this.https.getMyClassicQuizParticipations(classicQuizIds);
    };
}