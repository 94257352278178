export default class PlayerFilters {
    public countryId: string = null;
    public scope: string[] = null;
    public name: string = null;
    public playerIds: string[] = null;
    public limit: number = null;
    public page: number = null;
    public sortField: string = null;
    public sortOrder: string = null;

    constructor(filters: any) {
        this.countryId = filters.countryId ? filters.countryId : null;
        this.scope = filters.scope ? filters.scope : null;
        this.name = filters.name ? filters.name : null;
        this.playerIds = filters.playerIds ? filters.playerIds : null;
        this.limit = filters.limit ? filters.limit : null;
        this.page = filters.page ? filters.page : null;
        this.sortField = filters.sortField ? filters.sortField : null;
        this.sortOrder = filters.sortOrder ? filters.sortOrder : null;
    }

    public constructFilterUrl = () => {
        let url = '';
        if (this.countryId) {
            url = url + '&';
            url = url + `country_id=${this.countryId}`;
        }
        if (this.scope) {
            url = url + '&';
            url = url + `scope=${this.scope.join(",")}`;
        }
        if (this.name) {
            url = url + '&';
            url = url + `name=${this.name}`;
        }
        if (this.playerIds) {
            url = url + '&';
            url = url + `player_ids=${this.playerIds.join(",")}`;
        }
        if (this.limit) {
            url = url + "&";
            url = url + `limit=${this.limit}`;
        }
        if (this.page) {
            url = url + "&";
            url = url + `page=${this.page}`;
        }
        if (this.sortField) {
            url = url + "&";
            url = url + `sort_field=${this.sortField}`;
        }
        if (this.sortOrder) {
            url = url + "&";
            url = url + `sort_order=${this.sortOrder}`;
        }

        return url;
    };

}