import MainFiltersBQ from "../../../../Global/Models/Filters/MainFiltersBQ";

export type DiscussionsSortTypes =
  | "OLDEST"
  | "LATEST"
  | "INTERACTED"
  | "POPULAR";

export default class DiscussionsFilters extends MainFiltersBQ {
  public skipDeleted?: boolean;
  public sort?: DiscussionsSortTypes;

  constructor(filters: any) {
    super(filters);
    this.skipDeleted =
      filters && filters.skipDeleted !== undefined ? filters.skipDeleted : null;
    this.sort = filters && filters.sort ? filters.sort.toLocaleLowerCase() : null;
  }

  constructMainFiltersUrl?(): string {
    let url = super.constructMainFiltersUrl();

    if (this.skipDeleted !== null) {
      url += `&skip_deleted=${this.skipDeleted}`;
    }
    if (this.sort) {
      url += `&sort=${this.sort}`;
    }

    return url;
  }
}
