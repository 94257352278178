import FootballCountryModel from "../Country/FootballCountryModel"
import CompetitionBasicModel from "../Competition/CompetitionBasicModel";
import PlayerSquadModel from "../Player/PlayerSquadModel";
import TeamColors from "./TeamColors";

export default class TeamFullModel {
    public id: string = '';
    public country: FootballCountryModel = null;
    public gender: string = '';
    public assets: {} = null;
    public name: string = '';
    public competitions: CompetitionBasicModel[] = [];
    public code: string = '';
    public national: boolean = false;
    public fullName: string = '';
    public shortName: string = '';
    public isDeleted: boolean = false;
    public colors: TeamColors = new TeamColors();
    public squad: PlayerSquadModel[] = [];
}
