import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {
  initDisableCache,
  isDisabledCache,
  setBaseUrl,
} from "../Global/Helper";
import ClassicQuizParticipationBodyModel from "../Namespaces/MiniGames/Models/ClassicQuiz/ClassicQuizParticipationBodyModel";
import ClassicQuizFilters from "../Namespaces/MiniGames/Models/ClassicQuiz/Filters/ClassicQuizFilters";
import ClassicQuizRemapper from "../Namespaces/MiniGames/Models/ClassicQuiz/Remappers/ClassicQuizRemapper";
import Https from "./Https";

export default class MiniGamesHttps extends Https {
  private remapper: ClassicQuizRemapper = null;

  constructor(config: SDKConfiguraitonModel) {
    super(config, setBaseUrl(config.environment, "miniGames"));
    this.remapper = new ClassicQuizRemapper();
  }

  public getClassicQuizzes = async (
    filters?: ClassicQuizFilters,
    disableCache?: boolean
  ) => {
    let url = `/classic-quizzes${this.apiSignInUrl}`;

    if (filters) {
      url += filters.constructMainFiltersUrl();
    }

    url += isDisabledCache(disableCache) ? initDisableCache() : "";
    const warnMessage = "There was a problem with fetching classic quizzes";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapClassicQuizzes(data);
  };

  public getClassicQuizById = async (
    classicQuizId: string,
    disableCache?: boolean
  ) => {
    let url = `/classic-quizzes/${classicQuizId}${this.apiSignInUrl}`;

    if (isDisabledCache(disableCache)) {
      url += initDisableCache();
    }

    const warnMessage = "There was a problem with fetching classic quiz by ID";
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.remapper.remapClassicQuizById(data.data);
  };

  public participateInClassicQuiz = async (
    classicQuizId: string,
    questions: ClassicQuizParticipationBodyModel[],
    includeCorrectOptionIds?: boolean
  ) => {
    const url = `/classic-quizzes/${classicQuizId}/participate${this.apiSignInUrl}`;
    const warnMessage = `There was a problem with participating in the following classic quiz: ${classicQuizId}`;
    const method = "POST";
    const body = {
      questions: this.remapper.remapClassicQuizParticipationBody(questions),
      include_correct_options: !!includeCorrectOptionIds,
    };
    const data = await this.fetchWithAuth(url, warnMessage, method, body);

    return this.remapper.remapClassicQuizParticipation(data.data);
  };

  public getMyClassicQuizParticipations = async (classicQuizIds: string[]) => {
    const url = `/classic-quizzes/participations/${
      this.apiSignInUrl
    }&classic_quiz_ids=${classicQuizIds ? classicQuizIds.join(",") : ""}`;
    const warnMessage = `There was a problem with fetching user's own Classic Quiz participations`;
    const data = await this.fetchWithAuth(url, warnMessage);

    return this.remapper.remapUserClassicQuizParticipations(data.data);
  };
}
