import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { IdSchemaType } from "../../../Configurator/Types/ConfiguratorTypes";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import DiscussionsHttps from "../../../Https/DiscussionsHttps";
import ActivityService from "../../Activity/Service/ActivityService";
import DiscussionsFactory from "../Factory/DiscussionsFactory";
import DiscussionsConfigModel from "../Models/ClientFeatures/DiscussionsConfigModel";
import CreatePostParamBody from "../Models/CreatePostParamBody";
import DiscussionModel from "../Models/DiscussionModel";
import DiscussionsFilters from "../Models/Filters/DiscussionsFilters";
import PostByIdFullModel from "../Models/PostByIdFullModel";
import DiscussionsService from "../Service/DiscussionsService";
import { ReactionPostType, ReportReasonType } from "../Types/types";

export default class DiscussionsFacade {
  private idSchema: IdSchemaType = null;
  private https: DiscussionsHttps = null;
  private clientHttps: ClientHttps = null;
  private activityService: ActivityService = null;
  private service: DiscussionsService = null;
  private factory: DiscussionsFactory = null;

  constructor(config: SDKConfigurationModel) {
    this.idSchema = config.idSchema;
    this.https = new DiscussionsHttps(config);
    this.clientHttps = new ClientHttps(config);
    this.activityService = new ActivityService(config);
    this.service = new DiscussionsService(config);
    this.factory = new DiscussionsFactory();
  }

  public getById = async (discussionId: string) => {
    const discussion = await this.https.getById(discussionId);
    await this.remapTags(discussion, IdSchemaEnum.NATIVE, this.idSchema);

    return discussion;
  };

  public createPost = async (
    discussionId: string,
    body: CreatePostParamBody
  ) => {
    const copyBody = JSON.parse(JSON.stringify(body));
    await this.remapTags(copyBody, this.idSchema, IdSchemaEnum.NATIVE);
    const requestBody = this.factory.createRequestBodyFromParam(copyBody);

    return await this.https.createPost(discussionId, requestBody);
  };

  public getPostById = async (postId: string) => {
    const response = await this.https.getPostById(postId);
    const finalModel = await this.service.finalizePostFullModel([response]);

    return finalModel[0];
  };

  public updatePost = async (postId: string, content: string) => {
    return await this.https.updatePost(postId, content);
  };

  public deletePost = async (postId: string) => {
    return await this.https.deletePost(postId);
  };

  public getPosts = async (
    discussionId: string,
    filters?: DiscussionsFilters
  ) => {
    if (filters) {
      filters = new DiscussionsFilters(filters);
    }

    const response = await this.https.getPosts(discussionId, filters);
    response.data = await this.service.finalizePostFullModel(
      response.data as PostByIdFullModel[]
    );

    return response;
  };

  public getPostReplies = async (
    postId: string,
    filters?: DiscussionsFilters
  ) => {
    if (filters) {
      filters = new DiscussionsFilters(filters);
    }

    const response = await this.https.getPostReplies(postId, filters);
    response.data = await this.service.finalizePostFullModel(
      response.data as PostByIdFullModel[]
    );

    return response;
  };

  public reactToPost = async (postId: string, reaction: ReactionPostType) => {
    return await this.https.reactToPost(postId, reaction);
  };

  public reportPost = async (
    postId: string,
    reason: ReportReasonType,
    reasonDetails: string
  ) => {
    return await this.https.reportPost(postId, reason, reasonDetails);
  };

  public getOwnPosts = async (filters?: DiscussionsFilters) => {
    if (filters) {
      filters = new DiscussionsFilters(filters);
    }

    return await this.https.getOwnPosts(filters);
  };

  public getUserPosts = async (
    userId: string,
    filters?: DiscussionsFilters,
    disableCache?: boolean
  ) => {
    if (filters) {
      filters = new DiscussionsFilters(filters);
    }

    return await this.https.getUserPosts(userId, filters, disableCache);
  };

  public getConfig = async () => {
    return (await this.clientHttps.getConfig(
      FeatureConfigType.DISCUSSIONS
    )) as DiscussionsConfigModel;
  };

  private remapTags = async (
    object: DiscussionModel | CreatePostParamBody,
    fromSchema,
    toSchema
  ) => {
    if (
      this.idSchema != IdSchemaEnum.NATIVE &&
      object.context.tags &&
      object.context.tags.length
    ) {
      const copyTags = JSON.parse(JSON.stringify(object.context.tags));
      const remapIdsTags = await this.activityService.remapTagsIds(
        copyTags,
        fromSchema,
        toSchema
      );
      object.context.tags = remapIdsTags;
    }
  };
}
