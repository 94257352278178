import { setBaseUrl } from "../Global/Helper";
import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import FeatureConfigRemapper from "../Namespaces/Predictor/Models/Config/FeatureConfigRemapper";
import { FeaturesConfigModels } from "../Global/Types/GlobalTypes";
import Https from "./Https";
import { LocalCacheInterface } from "../Global/Interfaces/GlobalInterfaces";
import LocalCacheInstanceFactory from "../Global/Factories/LocalInstanceFactory";

export enum FeatureConfigType {
  PREDICTOR = "predictor",
  TOP_X = "topX",
  MATCH_QUIZ = "matchQuiz",
  LOYALTY = "loyalty",
  DISCUSSIONS = "discussions",
}

export default class ClientHttps extends Https {
  private featureConfigRemapper: FeatureConfigRemapper = null;
  private localStorage: LocalCacheInterface = null;
  private clientId: string = null;

  constructor(config: SDKConfigurationModel) {
    super(config, setBaseUrl(config.environment, "client"));
    this.clientId = config.clientId;
    this.localStorage =
      new LocalCacheInstanceFactory().createLocalCacheInstance(config.idSchema);
    this.featureConfigRemapper = new FeatureConfigRemapper();
  }

  public getConfig = async (
    feature: FeatureConfigType
  ): Promise<FeaturesConfigModels> => {
    let clientFeatures = this.getCachedFeatures();

    if (!clientFeatures) {
      const pathParams = `/clients/${this.clientId}/features`;
      const queryParams = this.apiSignInUrl.split("&")[0]; // We need only api key as a query param for client features
      const url = pathParams + queryParams;
      const warnMessage =
        "There was a problem fetching client features from Client API";
      const data = await this.fetchWithoutAuth(url, warnMessage);

      clientFeatures = data.data;
      this.localStorage.setClientFeatures(clientFeatures);
    }

    switch (feature) {
      case "predictor":
        return this.featureConfigRemapper.remapPredictorConfig(
          clientFeatures.predictor
        );
      case "topX":
        return this.featureConfigRemapper.remapTopXConfig(clientFeatures.top_x);
      case "matchQuiz":
        return this.featureConfigRemapper.remapMatchQuizConfig(
          clientFeatures.match_quiz
        );
      case "loyalty":
        return this.featureConfigRemapper.remapLoyaltyConfig(
          clientFeatures.loyalty
        );
      case "discussions":
        return this.featureConfigRemapper.remapDiscussionsConfig(
          clientFeatures.discussions
        );
    }
  };

  private getCachedFeatures = () => {
    const clientFeatures = this.getLocalStorageData();

    if (!clientFeatures || !Object.keys(clientFeatures).length) return;

    // When clientId has been changed (a new config is provided to SDK), we need to refetch the features.
    if (
      clientFeatures.predictor.client_id &&
      clientFeatures.predictor.client_id !== this.clientId
    ) {
      this.localStorage.deleteClientFeatures();

      return null;
    }

    return clientFeatures;
  };

  private getLocalStorageData = () => {
    const now = Math.floor(Date.now() / 1000);
    const expiration = this.localStorage.getExpiration("client", "features");

    if (expiration > now) {
      return this.localStorage.getClientFeatures();
    }

    return null;
  };
}
