export enum ErrorStatuses {
  INVALID_TEMPLATE = "INVALID_TEMPLATE",
  INVALID_PREDICTION = "INVALID_PREDICTION",
  INVALID_MARKET = "INVALID_MARKET",
  INVALID_FIELD = "INVALID_FIELD",
  INVALID_TYPE = "INVALID_TYPE",
  EXCEEDED_LENGTH = "EXCEEDED_LENGTH",
  INVALID_TOKEN = "invalid_token",
  UNAUTHORIZED = "unauthorized",
  INVALID_FILTERS = "invalid_filters",
  INVALID_AVATAR = "invalid_avatar",
  INVALID_METHOD_CALL = "invalid_method_call",
  INVALID_LIMIT = "invalid_limit"
}
