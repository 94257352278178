import {IRemapper} from "../../../../../Global/IRemapper";
import MatchFullModel from "../MatchFullModel";
import TeamBasicRemapper from "../../Team/Remappers/TeamBasicRemapper";
import CompetitionBasicRemapper from "../../Competition/Remappers/CompetitionBasicRemapper";
import StatsRemapper from "../Stats/StatsRemapper";
import ScoresRemapper from "../Scores/ScoresRemapper";
import LineupsRemapper from "../Lineups/LineupsRemapper";
import LineupsModel from "../Lineups/LineupsModel";
import TimelineModel from "../Timeline/TimelineModel"
import TimelineRemapper from "../Timeline/TimelineRemapper";

export default class MatchFullRemapper implements IRemapper<MatchFullModel> {
    private teamMapper: TeamBasicRemapper = null;
    private competitionsMapper: CompetitionBasicRemapper = null;
    private lineupsMapper: LineupsRemapper = null;
    private scoresMapper: ScoresRemapper = null;
    private statsMapper: StatsRemapper = null;
    private timelineMapper: TimelineRemapper = null;

    constructor() {
        this.teamMapper = new TeamBasicRemapper();
        this.competitionsMapper = new CompetitionBasicRemapper();
        this.lineupsMapper = new LineupsRemapper();
        this.scoresMapper = new ScoresRemapper();
        this.statsMapper = new StatsRemapper();
        this.timelineMapper = new TimelineRemapper();
    }

    fromResponse(response: any): MatchFullModel {
        try {
            const model = new MatchFullModel();
            model.id = response.id;
            model.kickoffAt = response.kickoff_at;
            model.finishedAt = response.finished_at;
            model.updatedAt = response.updated_at;
            model.isDeleted = response.is_deleted;
            model.homeTeam = response.home_team ? this.teamMapper.fromResponse(response.home_team) : null;
            model.awayTeam = response.away_team ? this.teamMapper.fromResponse(response.away_team) : null;
            model.lineupsConfirmed = response.lineups_confirmed;
            model.startedAt = response.started_at;
            model.minute = response.minute;
            model.scores = this.scoresMapper.fromResponse(response.scores);
            model.stats = this.statsMapper.fromResponse(response.stats);
            model.context = { competition: this.competitionsMapper.fromResponse(response.context.competition) };
            model.timeline = response.timeline? response.timeline.map((element: TimelineModel) => this.timelineMapper.fromResponse(element)) : [];
            model.status = {type: response.status.type, subType: response.status.sub_type};
            model.lineups = response.lineups ? this.lineupsMapper.fromResponse(response.lineups) : new LineupsModel();
            return model;
        } catch (e) {
            console.warn('There was a problem remapping the match response', e);
            throw e;
        }
    };

}