import MainFiltersBQ from "../../../../../Global/Models/Filters/MainFiltersBQ";

export default class ClassicQuizFilters extends MainFiltersBQ {
  flags?: string[] = null;
  status?: string = null;
  entityIds?: string[] = null;
  entityType?: string = null;

  constructor(filters: any) {
    super(filters);
    this.flags = filters && filters.flags ? filters.flags : null;
    this.status = filters && filters.status ? filters.status : null;
    this.entityIds = filters && filters.entityIds ? filters.entityIds : null;
    this.entityType = filters && filters.entityType ? filters.entityType : null;
  }

  public constructMainFiltersUrl? = () => {
    let url = super.constructMainFiltersUrl();

    if (this.flags) {
      url += `&flags=${this.flags.join(",")}`;
    }

    if (this.status) {
      url += `&status=${this.status}`;
    }

    if (this.entityIds) {
      url += `&entity_ids=${this.entityIds}`;
    }

    return url;
  };
}
