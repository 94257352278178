import TeamBasicModel from "../Team/TeamBasicModel";
import CompetitionBasicModel from "../Competition/CompetitionBasicModel";

export default class MatchBasicModel {
    public id: string = '';
    public kickoffAt: string = '';
    public finishedAt: string = '';
    public updatedAt: string = '';
    public availableMarkets: string[] = null;
    public homeTeam: TeamBasicModel = null;
    public awayTeam: TeamBasicModel = null;
    public isDeleted: boolean = false;
    public lineupsConfirmed: boolean = false;
    public startedAt: string = '';
    public minute: string = '';
    public scores: any = null;
    public context: { competition: CompetitionBasicModel } = { competition: null };
    public status: { type: string, subType: string } = { type: null, subType: null};
}
