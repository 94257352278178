import MetaProperties from "../../../../../Global/Models/Pagination/MetaProperties";
import ClassicQuizzesModel from "../ClassicQuizzesModel";
import ClassicQuizBasicModel from "../ClassicQuizBasicModel";
import ClassicQuizFullModel from "../ClassicQuizFullModel";
import ClassicQuizQuestionModel from "../ClassicQuizQuestionModel";
import ClassicQuizOptionModel from "../ClassicQuizOptionModel";
import ClassicQuizParticipationModel from "../ClassicQuizParticipationModel";
import ClassicQuizParticipationQuestionModel from "../ClassicQuizParticipationQuestionModel";
import ClassicQuizParticipationBodyModel from "../ClassicQuizParticipationBodyModel";
import ClassicQuizUserParticipation from "../ClassicQuizUserParticipationModel";

export default class ClassicQuizRemapper {
  public remapClassicQuizzes = (response: any): ClassicQuizzesModel => {
    response.meta.pagination = this.remapMetaPagination(
      response.meta.pagination
    );
    response.data = response.data.map((value) =>
      this.remapBasicClassicQuiz(value)
    );

    return response;
  };

  public remapClassicQuizById = (response: any): ClassicQuizFullModel => {
    const model = new ClassicQuizFullModel();

    model.id = response.id;
    model.title = response.title;
    model.description = response.description;
    model.type = response.type;
    model.images = response.images;
    model.participationCount = response.participation_count;
    model.questionsCount = response.questions_count;
    model.status = response.status;
    model.flags = response.flags;
    model.adContent = response.ad_content;
    model.rules = response.rules;
    model.customFields = response.custom_fields;
    model.labels = response.labels;
    model.time = response.time;
    model.averageScore = response.average_score;
    model.perfectScore = response.perfect_score;
    model.questions = response.questions.map((value) =>
      this.remapQuestions(value)
    );
    model.createdAt = response.created_at;
    model.updatedAt = response.updated_at;

    return model;
  };

  public remapClassicQuizParticipation = (
    participation: any
  ): ClassicQuizParticipationModel => {
    const model = new ClassicQuizParticipationModel();

    model.classicQuizId = participation.classic_quiz_id;
    model.userId = participation.user_id;
    model.questions = participation.questions
      ? participation.questions.map((value) =>
          this.remapParticipationQuestions(value)
        )
      : null;
    model.correctOptions = participation.correct_options;

    return model;
  };

  public remapClassicQuizParticipationBody = (
    body: ClassicQuizParticipationBodyModel[]
  ) => {
    return body.map((value) => {
      return {
        question_id: value.questionId,
        option_id: value.optionId,
      };
    });
  };

  public remapUserClassicQuizParticipations = (
    userParticipations: any[]
  ): ClassicQuizUserParticipation[] => {
    return userParticipations.map((value) => {
      const model = new ClassicQuizUserParticipation();

      model.classicQuizId = value.classic_quiz_id;
      model.correct = value.correct;
      model.classicQuizQuestions = value.classic_quiz_questions;

      return model;
    });
  };

  private remapMetaPagination = (pagination: any) => {
    const model = new MetaProperties();

    model.itemsPerPage = pagination.items_per_page;
    model.nextPageStartsAfter = pagination.next_page_starts_after;

    return model;
  };

  private remapBasicClassicQuiz = (basicClassicQuiz: any) => {
    const model = new ClassicQuizBasicModel();

    model.id = basicClassicQuiz.id;
    model.title = basicClassicQuiz.title;
    model.description = basicClassicQuiz.description;
    model.type = basicClassicQuiz.type;
    model.images = basicClassicQuiz.images;
    model.participationCount = basicClassicQuiz.participation_count;
    model.questionsCount = basicClassicQuiz.questions_count;
    model.status = basicClassicQuiz.status;
    model.flags = basicClassicQuiz.flags;
    model.customFields = basicClassicQuiz.custom_fields;
    model.labels = basicClassicQuiz.labels;
    model.time = basicClassicQuiz.time;
    model.averageScore = basicClassicQuiz.average_score;
    model.perfectScore = basicClassicQuiz.perfect_score;
    model.createdAt = basicClassicQuiz.created_at;
    model.updatedAt = basicClassicQuiz.updated_at;

    return model;
  };

  private remapQuestions = (question: any) => {
    const model = new ClassicQuizQuestionModel();

    model.questionId = question.question_id;
    model.question = question.question;
    model.images = question.images;
    model.options = question.options.map((value) => this.remapOptions(value));

    return model;
  };

  private remapOptions = (question: any) => {
    const model = new ClassicQuizOptionModel();

    model.optionId = question.option_id;
    model.option = question.option;
    model.images = question.images;

    return model;
  };

  private remapParticipationQuestions = (participationQuestions: any) => {
    const model = new ClassicQuizParticipationQuestionModel();

    model.questionId = participationQuestions.question_id;
    model.optionId = participationQuestions.option_id;
    model.correct = participationQuestions.correct;
    model.correctOptionId = participationQuestions.correct_option_id;

    return model;
  };
}
