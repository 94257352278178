import {IRemapper} from "../../../../../Global/IRemapper";
import FootballCountryRemapper from "../../Country/Remappers/FootballCountryRemapper";
import TeamBasicModel from "../TeamBasicModel";

export default class TeamBasicRemapper implements IRemapper<TeamBasicModel> {
    private countryRemapper: FootballCountryRemapper = null;

    constructor() {
        this.countryRemapper = new FootballCountryRemapper();
    }

    fromResponse(response: any): TeamBasicModel {
        try {
            const model = new TeamBasicModel();
            model.id = response.id;
            model.country = this.countryRemapper.countryFromResponse(response.country);
            model.gender = response.gender;
            model.assets = response.assets;
            model.code = response.code;
            model.national = response.national;
            model.fullName = response.full_name;
            model.shortName = response.short_name;
            model.name = response.name;
            model.isDeleted = response.is_deleted;

            return model;
        } catch (e) {
            console.warn("There was a problem remapping teams response", e);
            throw e;
        }
    };

}