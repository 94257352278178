import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import MarketsConfigModel from "../../Predictor/Models/Config/MarketsConfigModel";
import PredictorConfigModel from "../../Predictor/Models/Config/PredictorConfigModel";
import MatchBasicModel from "../Models/Match/MatchBasicModel";
import MatchFullModel from "../Models/Match/MatchFullModel";

type MatchData = MatchBasicModel[] | MatchFullModel;
type Match = MatchBasicModel | MatchFullModel;

export default class FootballService {
  private clientHttps: ClientHttps = null;

  constructor (config: SDKConfigurationModel) {
    this.clientHttps = new ClientHttps(config);
  }

  public setAvailableMarketsForMatch = async (matchData: MatchData): Promise<MatchData> => {
    const { fullCoverageCompetitions, markets } = await this.clientHttps.getConfig(FeatureConfigType.PREDICTOR) as PredictorConfigModel;

    if (Array.isArray(matchData)) {
      return matchData.map((match: MatchBasicModel) => {
        return this.setAvailableMarkets(match, fullCoverageCompetitions, markets);
      });
    } else if (typeof matchData === 'object' && Object.keys(matchData).length) {
      return this.setAvailableMarkets(matchData, fullCoverageCompetitions, markets) as MatchFullModel;
    }
  };

  private setAvailableMarkets = (matchData: Match, fullCoverageCompetitions: string[], markets: MarketsConfigModel): Match  => {
    const competitionId = matchData.context.competition?.id;

    if (competitionId && this.isMatchInCoverageCompetition(competitionId, fullCoverageCompetitions)) {
      matchData.availableMarkets = markets.all;

      return matchData;
    }

    matchData.availableMarkets = markets.main;

    return matchData;
  };

  private isMatchInCoverageCompetition = (competitionId: string, fullCoverageCompetitions: string[]): boolean => {
    let isInCoverage = false;

    if (fullCoverageCompetitions.includes(competitionId)) {
      isInCoverage = true;

      return isInCoverage;
    }

    return isInCoverage;
  }
}
