import RelatedEntity from "../../../../Global/Models/Related/RelatedEntity";
import TemplateMeta from "../Meta/TemplateMeta";
import TemplateByIdModel from "./TemplateByIdModel";
import TemplateModel from "./TemplateModel";

export default class TemplateRemapper {
    public remapResponse = (response: any[]): TemplateModel[] => {
        let newTemplates: TemplateModel[] = [];

        response.forEach((element: any) => {
            let model = new TemplateModel();

            model.id = element.id;
            model.name = element.name;
            model.description = element.description;
            model.type = element.type;
            model.fromDate = element.from_date;
            model.toDate = element.to_date;
            model.markets = element.markets ? element.markets : null;
            model.teamIds = element.team_ids ? element.team_ids : null;
            model.matchIds = element.match_ids ? element.match_ids : null;
            model.gameIds = element.game_ids ? element.game_ids : null;
            model.gameTypes = element.game_types ? element.game_types : null;
            model.competitionIds = element.competition_ids ? element.competition_ids : null;
            model.excludedProfileIds = element.excluded_profile_ids ? element.excluded_profile_ids : null;
            model.rules = element.rules ? element.rules : null;
            model.labels = element.labels ? element.labels : null;
            model.customFields = element.custom_fields ? element.custom_fields : null;
            model.flags = element.flags ? element.flags : null;
            model.images = element.images ? element.images : null;
            model.status = element.status ? element.status : null;
            model.automaticallyChangeStatus = element.automatically_change_status;
            model.createdAt = element.created_at;
            model.updatedAt = element.updated_at;
            model.related = element.related ? this.remapRelated(element.related) : null;

            newTemplates.push(model);
        })

        return newTemplates;
    };

    public remapResponseId = (response: any): TemplateByIdModel => {
        const model = new TemplateByIdModel();

        model.id = response.id;
        model.name = response.name;
        model.description = response.description;
        model.type = response.type;
        model.fromDate = response.from_date;
        model.toDate = response.to_date;
        model.markets = response.markets ? response.markets : null;
        model.teamIds = response.team_ids ? response.team_ids : null;
        model.matchIds = response.match_ids ? response.match_ids : null;
        model.gameIds = response.game_ids ? response.game_ids : null;
        model.gameTypes = response.game_types ? response.game_types : null;
        model.competitionIds = response.competition_ids ? response.competition_ids : null;
        model.excludedProfileIds = response.excluded_profile_ids ? response.excluded_profile_ids : null;
        model.rules = response.rules ? response.rules : null;
        model.flags = response.flags ? response.flags : null;
        model.images = response.images ? response.images : null;
        model.status = response.status ? response.status : null;
        model.automaticallyChangeStatus = response.automatically_change_status;
        model.related = response.related ? this.remapRelated(response.related) : null;
        model.labels = response.labels;
        model.customFields = response.custom_fields;
        model.adContent = response.ad_content;
        model.createdAt = response.created_at;
        model.updatedAt = response.updated_at;

        return model;
    };

    public remapMeta = (meta: any) => {
        let model: TemplateMeta = new TemplateMeta();

        model.pagination.currentPage = meta.pagination.current_page;
        model.pagination.itemsPerPage = meta.pagination.items_per_page;
        model.pagination.totalItems = meta.pagination.total_items;
        model.pagination.numberOfPages = meta.pagination.number_of_pages;
        model.filters.flags = meta.filters.flags;
        model.filters.status = meta.filters.status;
        model.filters.type = meta.filters.type;
        model.filters.activeFrom = meta.filters.active_from;
        model.filters.activeTo = meta.filters.active_to;
        model.filters.createdFrom = meta.filters.created_from;
        model.filters.createdTo = meta.filters.created_to;
        model.filters.updatedFrom = meta.filters.updated_from;
        model.filters.updatedTo = meta.filters.updated_to;

        return model;
    };

    private remapRelated = (related: any[]) => {
        return related.map((value: {[key: string]: string}) => {
            const model = new RelatedEntity();

            model.entityId = value.entity_id;
            model.entityType = value.entity_type;

            return model;
        });
    };
}