import ProfileModel from "../../Profile/Models/ProfileModel";
import PrivateLeagueModel from "./PrivateLeagueModel";

export default class AcceptPrivateLeagueModel {
  public profileId: string = "";
  public profileModel: ProfileModel = null;
  public leagueId: string = "";
  public leagueModel: PrivateLeagueModel = null;
  public message: string = "";
}
