import TeamBasicModel from "../Team/TeamBasicModel";
import StatsModel from "./Stats/StatsModel";
import LineupsModel from "./Lineups/LineupsModel";
import CompetitionBasicModel from "../Competition/CompetitionBasicModel";
import ScoresModel from "./Scores/ScoresModel";
import TimelineModel from "./Timeline/TimelineModel";
import MatchStatus from "./Status/MatchStatus";

export default class MatchFullModel {
    public id: string = '';
    public kickoffAt: string = '';
    public finishedAt: string = '';
    public updatedAt: string = '';
    public availableMarkets: string[] = null;
    public isDeleted: boolean = false;
    public homeTeam: TeamBasicModel = null;
    public awayTeam: TeamBasicModel = null;
    public lineupsConfirmed: boolean = false;
    public startedAt: string = '';
    public minute: string = '';
    public scores: ScoresModel = null;
    public stats: StatsModel = null;
    public context: { competition: CompetitionBasicModel } = { competition: null };
    public timeline: TimelineModel[] = [];
    public status: MatchStatus = new MatchStatus();
    public lineups: LineupsModel = null;
}
