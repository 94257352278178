import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import {
  initDisableCache,
  isDisabledCache,
  setBaseUrl,
} from "../Global/Helper";
import PaginationModel from "../Global/Models/Pagination/PaginationModel";
import FootballPaginationModel from "../Namespaces/Football/Models/Pagination/FootballPaginationModel";
import LoyaltyFilters from "../Namespaces/Loyalty/Models/Filters/LoyaltyFilters";
import RankingsFilters from "../Namespaces/Loyalty/Models/Filters/RankingsFilters";
import LeaderboardRemapper from "../Namespaces/Loyalty/Models/Leaderboard/LeaderboardRemapper";
import RankingsRemapper from "../Namespaces/Loyalty/Models/Rankings/RankingsRemapper";
import TemplateModel from "../Namespaces/Loyalty/Models/Template/TemplateModel";
import TemplateRemapper from "../Namespaces/Loyalty/Models/Template/TemplateRemapper";
import HighestSuccessRateModel from "../Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateModel";
import HighestSuccessRateRemapper from "../Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateRemapper";
import EntitiesFollowsFilters from "../Namespaces/Loyalty/Models/Filters/EntitiesFollowsFilters";
import EntitiesFollows from "../Namespaces/Loyalty/Models/EntitiesFollows/EntitiesFollows";
import TemplateByIdModel from "../Namespaces/Loyalty/Models/Template/TemplateByIdModel";
import HighestSuccessRateFilters from "../Namespaces/Loyalty/Models/Filters/HighestSuccessRateFilters";
import Https from "./Https";
import TemplateFilters from "../Namespaces/Loyalty/Models/Filters/TemplateFilters";
import { LeagueType } from "../Namespaces/PrivateLeagues/Types/types";
import { FiltersInterface } from "../Global/Interfaces/GlobalInterfaces";

export default class LoyaltyHttps extends Https {
  private templateRemapper: TemplateRemapper = null;
  private leaderboardRemapper: LeaderboardRemapper = null;
  private rankingsRemapper: RankingsRemapper = null;
  private highestSuccessRateRemapper: HighestSuccessRateRemapper = null;

  constructor(config: SDKConfiguraitonModel) {
    super(config, setBaseUrl(config.environment, "loyalty"));
    this.templateRemapper = new TemplateRemapper();
    this.leaderboardRemapper = new LeaderboardRemapper();
    this.rankingsRemapper = new RankingsRemapper();
    this.highestSuccessRateRemapper = new HighestSuccessRateRemapper();
  }

  public getTemplates = async (
    filters: TemplateFilters,
    disableCache: boolean
  ): Promise<PaginationModel | TemplateModel[]> => {
    let url = filters
      ? `/leaderboard/templates/${
          this.apiSignInUrl
        }${filters.constructFiltersUrl()}`
      : `/leaderboard/templates/${this.apiSignInUrl}`;
    url += isDisabledCache(disableCache) ? initDisableCache() : "";
    const warnMessage = "There was a problem fetching the list of templates";
    const data = await this.fetchWithoutAuth(url, warnMessage);
    const copyResponse = JSON.parse(JSON.stringify(data));
    copyResponse.meta = this.templateRemapper.remapMeta(data.meta);
    copyResponse.data = this.templateRemapper.remapResponse(data.data);

    return copyResponse;
  };

  public getTemplateById = async (
    templateId: string,
    disableCache: boolean
  ): Promise<TemplateByIdModel> => {
    const url = isDisabledCache(disableCache)
      ? `/leaderboard/templates/${templateId}${
          this.apiSignInUrl
        }${initDisableCache()}`
      : `/leaderboard/templates/${templateId}${this.apiSignInUrl}`;
    const warnMessage = `There was a problem requesting the following template: ${templateId}`;
    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.templateRemapper.remapResponseId(data.data);
  };

  public getLeaderboard = async (
    templateId: string,
    disableCache: boolean,
    filters: LoyaltyFilters
  ): Promise<PaginationModel> => {
    let url = isDisabledCache(disableCache)
      ? `/leaderboard/${templateId}/${this.apiSignInUrl}${initDisableCache()}`
      : `/leaderboard/${templateId}/${this.apiSignInUrl}`;
    const warnMessage = `There was a problem fetching the leaderboard for the following template: ${templateId}`;

    if (filters) {
      url += `${filters.constructFiltersUrl()}`;
    }

    const data = await this.fetchWithoutAuth(url, warnMessage);
    const copyResponse = JSON.parse(JSON.stringify(data));

    return this.leaderboardRemapper.remapResponse(copyResponse);
  };

  public getLeaderboardForGame = async (
    gameId: string,
    disableCache: boolean,
    filters: LoyaltyFilters
  ): Promise<PaginationModel> => {
    let url = isDisabledCache(disableCache)
      ? `/leaderboard/games/${gameId}/${this.apiSignInUrl}${initDisableCache()}`
      : `/leaderboard/games/${gameId}/${this.apiSignInUrl}`;
    const warnMessage = `There was a problem fetching the leaderboard for the following game: ${gameId}`;

    if (filters) {
      url += `${filters.constructFiltersUrl()}`;
    }

    const data = await this.fetchWithoutAuth(url, warnMessage);
    const copyResponse = JSON.parse(JSON.stringify(data));

    return this.leaderboardRemapper.remapResponse(copyResponse);
  };

  public getUserRankings = async (
    userId: string,
    filters: RankingsFilters,
    disableCache: boolean
  ): Promise<FootballPaginationModel> => {
    let url = `/leaderboard/users/${userId}/rankings/${this.apiSignInUrl}`;
    const warnMessage = `There was a problem fetching the rankings for the following user: ${userId}`;

    if (filters) {
      url += `${filters.constructFiltersUrl()}`;
    }

    if (isDisabledCache(disableCache)) {
      url += `${initDisableCache()}`;
    }

    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.rankingsRemapper.remapRankings(data);
  };

  public getHighestSuccessRate = async (
    filters: HighestSuccessRateFilters,
    disableCache: boolean
  ): Promise<HighestSuccessRateModel[]> => {
    let url = `/statistics/top${this.apiSignInUrl}`;
    const warnMessage = "There was a problem fetching highest success rate";

    if (filters) {
      url += `${filters.constructFiltersUrl()}`;
    }

    if (isDisabledCache(disableCache)) {
      url += `${initDisableCache()}`;
    }

    const data = await this.fetchWithoutAuth(url, warnMessage);

    return this.highestSuccessRateRemapper.remapHighestSuccessRate(data.data);
  };

  public getEntitiesFollows = async (
    filters: EntitiesFollowsFilters,
    disableCache: boolean
  ): Promise<EntitiesFollows> => {
    let url = isDisabledCache(disableCache)
      ? `/statistics/follows${this.apiSignInUrl}${initDisableCache()}`
      : `/statistics/follows${this.apiSignInUrl}`;
    const warnMessage = "There was a problem fetching follows for entities";

    if (filters.footballCompetitions) {
      url += `&competitions=${filters.footballCompetitions.join(",")}`;
    }

    if (filters.footballTeams) {
      url += `&teams=${filters.footballTeams.join(",")}`;
    }

    if (filters.footballPlayers) {
      url += `&players=${filters.footballPlayers.join(",")}`;
    }

    const data = await this.fetchWithoutAuth(url, warnMessage);

    return data.data;
  };

  public getUserRankingsForPrivateLeagueAndChallenges = async (
    userId: string,
    type: LeagueType,
    filters: FiltersInterface
  ) => {
    let url = `/private-league/users/${userId}/rankings/${
      this.apiSignInUrl
    }&private_league_type=${
      type === "PRIVATE_LEAGUE" ? "private" : "one_vs_one"
    }`;
    const warnMessage = `There was a problem fetching user rankings for ${type}`;

    if (filters) {
      url += `${filters.constructFiltersUrl()}`;
    }

    const response = await this.fetchWithoutAuth(url, warnMessage);
    const data: any =
      type === "PRIVATE_LEAGUE"
        ? this.rankingsRemapper.remapRankingsPrivateLeague(response)
        : this.rankingsRemapper.remapRankingsChallenge(response);

    return data;
  };
}
