import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import ProfileModel from "../../Profile/Models/ProfileModel";
import Profile from "../../Profile/Profile";
import PostByIdFullModel from "../Models/PostByIdFullModel";

export default class DiscussionsService {
  private readonly profile: Profile = null;

  constructor(config: SDKConfigurationModel) {
    this.profile = new Profile(config);
  }

  public finalizePostFullModel = async (posts: PostByIdFullModel[]) => {
    const userIds = posts.map((post: PostByIdFullModel) => post.userId);

    const profiles = await this.profile.getByIds(userIds);

    return posts.map((post: PostByIdFullModel) => {
      post.userModel = this.extractModelById(profiles, post.userId);

      return post;
    });
  };

  private extractModelById = (profiles: ProfileModel[], id: string) => {
    if (profiles.length) {
      const profile = profiles.find((profile: ProfileModel) => profile.id === id);

      if (profile) {
        delete profile.interests;

        return profile
      }

      return null;
    }

    return null;
  };
}
