export enum ActionsEnum {
  LIKE = "like",
  DISLIKE = "dislike",
  PAGE_VIEW = "page_view",
  CONTENT_CONSUMED = "content_consumed",
  SHARE = "share",
  COMMENT = "comment",
  CLICK_AD = "click_ad",
  CONVERSION = "conversion",
  PREDICTION_MADE = "prediction_made",
  CONVERT = "convert",
  MANAGE_INTERESTS = "manage_interests",
  GAME_PARTICIPATION = "game_participation",
  ARTICLE_CONSUMED = "article_consumed",
  VIDEO_CONSUMED = "video_consumed",
  SHORT_VIDEO_CONSUMED = "short_video_consumed",
  LONG_VIDEO_CONSUMED = "long_video_consumed",
  AUDIO_CONSUMED = "audio_consumed",
  GALLERY_CONSUMED = "gallery_consumed",
  IMAGE_CONSUMED = "image_consumed",
  POLL_PARTICIPATION = "poll_participation",
}
