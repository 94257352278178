import TemplateModel from "../../../Loyalty/Models/Template/TemplateModel";
import PrivateLeagueModel from "../../../PrivateLeagues/Models/PrivateLeagueModel";

export default class ChallengeRankingsModel {
  public templateId: string = "";
  public templateModel: TemplateModel = null;
  public challengeId: string = "";
  public challengeModel: PrivateLeagueModel = null;
  public position: string = "";
  public points: number = 0;
  public type: string = "challenge";
}
