import { FiltersInterface } from "../../../../Global/Interfaces/GlobalInterfaces";

export default class LoyaltyFilters implements FiltersInterface {
    public limit?: number = null;
    public page?: number = null;

    constructor(filters: any) {
        this.limit = filters.limit ? filters.limit : null;
        this.page = filters.page ? filters.page : null;
    }

    /*
    Super calls are redirected to the prototype and we cannot use a property (arrow function) and we need to use a method.
    */
    public constructFiltersUrl (): string {
        let url = '';

        if (this.limit) {
            url += '&';
            url += `limit=${this.limit}`;
        }
        if (this.page) {
            url += '&';
            url += `page=${this.page}`;
        }

        return url;
    };
}