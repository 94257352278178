import ReactionModel from "./ReactionModel";
import ReportModel from "./ReportModel";
import VersionsModel from "./VersionsModel";

export default class PostByIdBasicModel {
  public id: string = "";
  public userId: string = "";
  public discussionId: string = "";
  public content: string = "";
  public repliesCount: number = 0;
  public replyId: string = "";
  public reactions: ReactionModel[] = [];
  public reactionsCount: number = 0;
  public reports: ReportModel[] = [];
  public reportsCount: number = 0;
  public versions: VersionsModel[] = null;
  public privatePost: boolean = false;
  public deleted: boolean = false;
  public deletedAt: string = "";
  public deletedBy: string = "";
  public moderated: boolean = false;
  public moderatedAt: string = "";
  public moderatedBy: string = "";
  public moderatedReason: string = "";
  public createdAt: string = "";
  public updatedAt: string = "";
}
