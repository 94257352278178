export enum ValidationMessageEnum {
    API_KEY = 'The field apiKey with valid value must be provided to use Fans United JS SDK',
    CLIENT_ID = 'The field clientId must be provided to use Fans United JS SDK',
    LANG_MISSING = 'If no lang is passed the default one will be set which is: en',
    LANG_INVALID = 'This lang value is not supported, the default one will be set which is: en',
    ID_SCHEMA_MISSING = 'If no idSchema is passed the default one will be set which is: native',
    ID_SCHEMA_INVALID = 'This idSchema value is not supported, the default one will be set which is: native',
    ENVIRONMENT_MISSING = 'If no environment is passed the default one will be set which is: prod',
    ENVIRONMENT_INVALID = 'This environment value is not supported, the default one will be set which is: prod',
    ERROR_HANDLING_MODE_MISSING = 'If no errorHandlingMode is passed the default one will be set which is: default',
    ERROR_HANDLING_MODE_INVALID = 'This errorHandlingMode value is not supported, the default one will be set which is: default',
    AUTH_PROVIDER_CONFIG_PROP_MISSING = 'The following property is missing from the authProvider object:',
    AUTH_PROVIDER_CONFIG_INVALID_PROP = 'You have passed a property which is not supported for the authentication provider:',
    AUTH_PROVIDER_CONFIG_MISSING = 'The field authProvider must be provided to use this SDK',
    AUTH_PROVIDER_CONFIG_MISSING_CONFIG = 'The field authProvider has missing properties',
}
