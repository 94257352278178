import FootballCountryModel from "../Country/FootballCountryModel";

export default class TeamBasicModel {
    public id: string = '';
    public country: FootballCountryModel = null;
    public gender: string = '';
    public assets: {} = null;
    public name: string = '';
    public code: string = '';
    public national: boolean = false;
    public fullName: string = '';
    public shortName: string = '';
    public isDeleted: boolean = false;
}
