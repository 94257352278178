import ImagesModel from "../../../../Global/Models/Images/ImagesModel";

export default class ClassicQuizBasicModel {
  public id: string = null;
  public title: string = null;
  public description: string = null;
  public type: string = null;
  public images: ImagesModel = new ImagesModel();
  public participationCount: number = null;
  public questionsCount: number = null;
  public status: string = null;
  public flags: string[] = null;
  public customFields: Record<string, string> = null;
  public labels: Record<string, string> = null;
  public time: number = null;
  public averageScore: number = null;
  public perfectScore: number = null;
  public createdAt: string = null;
  public updatedAt: string = null;
}
