import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import MiniGamesFacade from "./Facade/MiniGamesFacade";
import ClassicQuizParticipationBodyModel from "./Models/ClassicQuiz/ClassicQuizParticipationBodyModel";
import ClassicQuizFilters from "./Models/ClassicQuiz/Filters/ClassicQuizFilters";

export default class MiniGames {
  private facade: MiniGamesFacade = null;

  constructor(config: SDKConfigurationModel) {
    this.facade = new MiniGamesFacade(config);
  }

  public getClassicQuizzes = async (filters?: ClassicQuizFilters, disableCache?: boolean) => {
    return await this.facade.getClassicQuizzes(filters, disableCache);
  };

  public getClassicQuizById = async (classicQuizId: string, disableCache?: boolean) => {
    return await this.facade.getClassicQuizById(classicQuizId, disableCache);
  };

  public participateInClassicQuiz = async (classicQuizId: string, questions: ClassicQuizParticipationBodyModel[], includeCorrectOptionIds?: boolean) => {
    return await this.facade.participateInClassicQuiz(classicQuizId, questions, includeCorrectOptionIds);
  };

  public getMyClassicQuizParticipations = async (classicQuizIds: string[]) => {
    return await this.facade.getMyClassicQuizParticipations(classicQuizIds);
  };
}
