import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import PrivateLeaguesFacade from "./Facade/PrivateLeaguesFacade";
import InvitationFilters from "./Models/Filters/InvitationFilters";
import PrivateLeagueFilters from "./Models/Filters/PrivateLeagueFilters";
import PrivateLeaguePostsFilters from "./Models/Filters/PrivateLeaguePostsFilters";
import PrivateLeaguePredictionsFilters from "./Models/Filters/PrivateLeaguePredictionsFilters";
import PrivateLeagueParamCreateBody from "./Models/PrivateLeagueParamCreateBody";
import PrivateLeagueParamUpdateBody from "./Models/PrivateLeagueParamUpdateBody";

export default class PrivateLeagues {
  private privateLeaguesFacade: PrivateLeaguesFacade = null;

  constructor(config: SDKConfigurationModel) {
    this.privateLeaguesFacade = new PrivateLeaguesFacade(config);
  }

  public create = async (body: PrivateLeagueParamCreateBody) => {
    return await this.privateLeaguesFacade.create(body);
  };

  public update = async (
    leagueId: string,
    body: PrivateLeagueParamUpdateBody
  ) => {
    return await this.privateLeaguesFacade.update(leagueId, body);
  };

  public updateTemplate = async (
    leagueId: string,
    newTemplateId: string,
    oldTemplateName: string
  ) => {
    return await this.privateLeaguesFacade.updateTemplate(
      leagueId,
      newTemplateId,
      oldTemplateName
    );
  };

  public delete = async (leagueId: string) => {
    return await this.privateLeaguesFacade.delete(leagueId);
  };

  public getById = async (leagueId: string, disableCache?: boolean) => {
    return await this.privateLeaguesFacade.getById(leagueId, disableCache);
  };

  public invite = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeaguesFacade.invite(leagueId, profiles);
  };

  public deleteInvitation = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeaguesFacade.deleteInvitation(leagueId, profiles);
  };

  public accept = async (leagueId: string) => {
    return await this.privateLeaguesFacade.accept(leagueId);
  };

  public reject = async (leagueId: string) => {
    return await this.privateLeaguesFacade.reject(leagueId);
  };

  public banUsers = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeaguesFacade.banUsers(leagueId, profiles);
  };

  public unbanUsers = async (leagueId: string, profiles: string[]) => {
    return await this.privateLeaguesFacade.unbanUsers(leagueId, profiles);
  };

  public join = async (code: string) => {
    return await this.privateLeaguesFacade.join(code);
  };

  public leave = async (leagueId: string) => {
    return await this.privateLeaguesFacade.leave(leagueId);
  };

  public getMyLeagues = async (filters?: PrivateLeagueFilters) => {
    return await this.privateLeaguesFacade.getMyLeagues(filters);
  };

  public getInvitations = async (filters?: InvitationFilters) => {
    return await this.privateLeaguesFacade.getInvitations(filters);
  };

  public getReportedPosts = async (
    leagueId: string,
    filters?: PrivateLeaguePostsFilters
  ) => {
    return await this.privateLeaguesFacade.getReportedPosts(leagueId, filters);
  };

  public getPredictions = async (
    leagueId: string,
    filters?: PrivateLeaguePredictionsFilters
  ) => {
    return await this.privateLeaguesFacade.getPredictions(leagueId, filters);
  };

  public moderatePost = async (postId: string, moderationReason: string) => {
    return await this.privateLeaguesFacade.moderatePost(
      postId,
      moderationReason
    );
  };
}
