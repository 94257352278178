import TemplateModel from "../../Loyalty/Models/Template/TemplateModel";
import PastTemplateModel from "./PastTemplateModel";

export default class PrivateLeagueModel {
  public id: string = "";
  public name: string = "";
  public description: string = "";
  public type: string = "";
  public members: string[] = [];
  public banned: string[] = [];
  public invites: string[] = [];
  public administrators: string[] = [];
  public invitationCode: string = "";
  public templateId: string = "";
  public templateModel: TemplateModel = null;
  public pastTemplates: PastTemplateModel[] = [];
  public usersCanInviteUsers: boolean = false;
  public scoringStartsAt: string = "";
  public pinnedPosts: string[] = [];
}
